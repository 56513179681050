import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Header from './Components/Header/Header';
import Language from './Components/Language/Language';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home/Home';
import "./index.scss";

function App() {
  return (
    <BrowserRouter basename="/">
      <Header />
      <Language />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;