import { NavLink } from "react-router-dom"; // import de NavLink pour naviguer entre chaque page
import "./header.scss" // import du scss pour la mise en page
import images from "../../utils/images";
const Header = () => {
    return (
        <div className="Header">
            <div className="links__header">
                <NavLink to="/" className="hover"><images.arrowUp className="house" /></NavLink>
            </div>

            <div className="links__header">
                <NavLink className="hover" to="/compétences">Présentation</NavLink>
            </div>

            <div className="links__header">
                <NavLink className="hover" to="/compétences">Compétences</NavLink>
            </div>

            <div className="links__header">
                <NavLink className="hover" to="/portfolio">Portfolio</NavLink>
            </div>

            <div className="links__header">
                <NavLink className="hover" to="/contact">Contact</NavLink>
            </div>
        </div>
    );
};

export default Header;