import { ReactComponent as CodeSVG } from '../assets/icons/code-solid.svg';
import { ReactComponent as DatabaseSVG } from '../assets/icons/database-solid.svg';
import { ReactComponent as CellSVG } from '../assets/icons/cell-phone.svg';
import { ReactComponent as GlobeSVG } from '../assets/icons/globe-solid.svg';
import { ReactComponent as NodejsSVG } from '../assets/icons/node-js.svg';
import { ReactComponent as ReactSVG } from '../assets/icons/react.svg';
import { ReactComponent as XSVG } from '../assets/icons/x.svg';
import { ReactComponent as ArrowUpSVG } from '../assets/icons/up-arrow.svg';
import { ReactComponent as EnFlagSVG } from '../assets/icons/en-flag.svg';
import { ReactComponent as FrFlagSVG } from '../assets/icons/fr-flag.svg';
import { ReactComponent as StarCompletedSVG } from '../assets/icons/star-completed.svg';
import { ReactComponent as StarEmptySVG } from '../assets/icons/star-empty.svg';
import { ReactComponent as StarComplitedSVG } from '../assets/icons/star-complited.svg';

const images = {
  /**
   * Icons
   */
  code: CodeSVG,
  database: DatabaseSVG,
  cell: CellSVG,
  globe: GlobeSVG,
  nodejs: NodejsSVG,
  react: ReactSVG,
  x: XSVG,
  arrowUp: ArrowUpSVG,
  frFlag: FrFlagSVG,
  enFlag: EnFlagSVG,
  mail: 'images/mail-icon.png',
  github: 'images/github.gif',
  linkedin: 'images/linkedin.gif',

  /**
   * Stars
   */
  starCompleted: StarCompletedSVG,
  starEmpty: StarEmptySVG,
  starComplited: StarComplitedSVG,

  /**
   * Backgrounds
   */
  space: 'images/space.jpg',
  milkyWayMobile: 'images/milkyway-mobile.jpg',
  stars: 'images/stars.jpg',

  /**
   * Pictures
   */
  kasa: 'images/kasa.png',
  shop: 'images/shop.png',
  team: 'images/team.png',
};

export default images;