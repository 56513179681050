import { useState } from "react"; // Pour gérer l'état
import "./portfolio.scss"; // SCSS pour la mise en page
import images from "../../utils/images"; // Import des SVG
import Card from "../../Components/Card/Card";

const Language = () => {
    // Initialisation des projets
    const [projects] = useState([
        {
            id: 1,
            name: "KASA",
            technologie: "REACT",
            img: images.kasa,
            link: "https://kasadespapel.netlify.app",
            description: "Site de recherche immobilière",
        },
        {
            id: 2,
            name: "ESOU",
            technologie: "REACT",
            img: images.shop,
            link: "https://projetdwwm.netlify.app/",
            description: "Site de vente de vêtements",
        },
        {
            id: 3,
            name: "TEAM",
            technologie: "HTML5 CSS3",
            img: images.team,
            link: "https://team9512.netlify.app",
            description: "Projet effectué lors de la formation",
        },
    ]);

    const [search, setSearch] = useState("");

    const handleSearchName = (e) => {
        const value = e.target.value;
        setSearch(value);
    };

    return (
        <div className="Portfolio">
            <div className="search">
                <h3>Projets réalisés :</h3>
                <input
                    type="text"
                    name="searchBar"
                    id="searchBar"
                    placeholder="Nom du projet"
                    onChange={handleSearchName}
                />
            </div>
            <div className="list">
                {projects
                    .filter((val) => val && val.name && val.name.toLowerCase().includes(search.toLowerCase()))
                    .map((project) => (
                        <Card key={project.id} project={project} />
                    ))}
            </div>
        </div>
    );
};

export default Language;
