import { useState } from "react"; // Pour gérer l'état
import "./language.scss"; // SCSS pour la mise en page
import images from "../../utils/images"; // Import des SVG

const Language = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [selectedLang, setSelectedLang] = useState("FR");

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleLangChange = (lang) => {
        setSelectedLang(lang);
        setMenuOpen(false);
    };

    return (
        <div className="Language">
            <button className="language-btn" onClick={toggleMenu}>
                {selectedLang === "EN" ? (
                    <images.enFlag className="language-icon" alt="English" />
                ) : (
                    <images.frFlag className="language-icon" alt="Français" />
                )}
                <span>{selectedLang}</span>
                <span className="arrow">&#9662;</span>
            </button>
            {menuOpen && (
                <div className="language-menu">
                    <div
                        className="language-option"
                        onClick={() => handleLangChange("EN")}
                    >
                        <images.enFlag className="language-icon" alt="English" />
                        English
                    </div>
                    <div
                        className="language-option"
                        onClick={() => handleLangChange("FR")}
                    >
                        <images.frFlag className="language-icon" alt="Français" />
                        Français
                    </div>
                </div>
            )}
        </div>
    );
};

export default Language;
