import "./home.scss";
import images from "../../utils/images";
import Portfolio from "../../Components/Portfolio/Portfolio"
import Skills from "../../Components/Skills/Skills"

function Home() {
    return (
        <div className="Home">
            <div className="top__home--background">
                <div className="top__home gap-3 column slide-up">
                    <div className="intro">
                        <h2><label>Bonjour, je m'appelle Damien. Actuellement en formation de</label></h2>
                        <h1><label>Concepteur développeur d'applications</label></h1>
                        <h3>
                            <label>Je recherche une <span>alternance</span> en île-de-france et
                                alentours <span>sur un rythme de 3 semaines en entreprise et 1 semaine en formation</span></label>
                        </h3>
                    </div>

                    <div className="top__information">
                        <div>
                            <images.code className="color__svg--front svg__size" alt="front-end" />
                            <label>front-end</label>
                        </div>
                        <div>
                            <images.database className="color__svg--back svg__size" alt="back-end" />
                            <label>back-end</label>
                        </div>
                        <div>
                            <images.cell className="color__svg--mobile svg__size" alt="mobile" />
                            <label>mobile</label>
                        </div>
                        <div>
                            <images.globe className="color__svg--anglais svg__size" alt="anglais" />
                            <label>anglais</label>
                        </div>
                    </div>
                </div>
            </div>
            <section className="portfolio">
                <Portfolio />
            </section>
            <section className="skills">
                <Skills />
            </section>
            <Portfolio />

        </div>
    );
}

export default Home;