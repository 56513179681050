// import "./skills.scss";
// import { useState } from "react";
// import images from "../../utils/images";

// function Skills() {

//     const [skillsFront] = useState([
//         { id: 1, title: "HTML", percentage: 85, color: "red" },
//         { id: 2, title: "CSS", percentage: 80, color: "blue" },
//         { id: 3, title: "Javascript", percentage: 60, color: "yellow" },
//     ]);

//     const [skillsBack] = useState([
//         { id: 1, title: "NodeJS", percentage: 75, color: "green" },
//         { id: 2, title: "Symfony", percentage: 65, color: "black" },
//         { id: 3, title: "PHP", percentage: 85, color: "orange" },
//     ]);

//     const ProgressBar = ({ percentage, color }) => (
//         <div className="progress-bar">
//             <div
//                 className="progress"
//                 style={{
//                     width: `${percentage}%`,
//                     backgroundColor: color,
//                 }}
//             ></div>
//         </div>
//     );
    

//     return (
//         <div className="Skills">
//             <div className="skills__front left" data-aos="fade-right">
//                 {skillsFront.map((skill) => (
//                     <div className="skill__front" key={skill.id}>
//                         <h1 style={{ color: skill.color }}>{skill.title}</h1>
//                         <ProgressBar percentage={skill.percentage} color={skill.color} />
//                     </div>
//                 ))}
//             </div>

//             <div className="skills__back right">
//                 {skillsBack.map((skill) => (
//                     <div className="skill__back" key={skill.id}>
//                         <h1 style={{ color: skill.color }}>{skill.title}</h1>
//                         <ProgressBar percentage={skill.percentage} color={skill.color} />
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// }

// export default Skills;
import "./skills.scss";
import { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Importez le CSS de la bibliothèque AOS
import images from "../../utils/images";

function Skills() {
    // Initialiser AOS lors du montage du composant
    useEffect(() => {
        AOS.init({
            duration: 800, // Réduisez la durée des animations
            easing: "ease-in-out", // Gardez une transition fluide
            once: true, // Évitez les animations répétées
            mirror: false, // Empêchez les animations lorsqu'on fait défiler vers le haut
        });
    }, []);

    const [skillsFront] = useState([
        { id: 1, title: "HTML", percentage: 85, color: "red" },
        { id: 2, title: "CSS", percentage: 80, color: "blue" },
        { id: 3, title: "Javascript", percentage: 60, color: "yellow" },
    ]);

    const [skillsBack] = useState([
        { id: 1, title: "NodeJS", percentage: 75, color: "green" },
        { id: 2, title: "Symfony", percentage: 65, color: "black" },
        { id: 3, title: "PHP", percentage: 85, color: "orange" },
    ]);

    const ProgressBar = ({ percentage, color }) => (
        <div className="progress-bar">
            <div
                className="progress"
                style={{
                    width: `${percentage}%`,
                    backgroundColor: color,
                }}
            ></div>
        </div>
    );

    return (
        <div className="Skills">
            {/* Ajout des animations AOS */}
            <div className="skills__front left" data-aos="fade-right">
                {skillsFront.map((skill) => (
                    <div className="skill__front" key={skill.id}>
                        <h1 style={{ color: skill.color }}>{skill.title}</h1>
                        <ProgressBar percentage={skill.percentage} color={skill.color} />
                    </div>
                ))}
            </div>

            <div className="skills__back right" data-aos="fade-left">
                {skillsBack.map((skill) => (
                    <div className="skill__back" key={skill.id}>
                        <h1 style={{ color: skill.color }}>{skill.title}</h1>
                        <ProgressBar percentage={skill.percentage} color={skill.color} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Skills;
