import { Link } from 'react-router-dom'
import "./card.scss"

function Card(props) {

    const { project } = props

    return (
        <div className="Card">
            <Link to={project.link} className="card" key={project.id} target='_blank' >
                <img className='img__site' src={project.img} alt="du site" />
                <div>
                    <h2>{project.name}</h2>
                    <h5>{project.description}</h5>
                    <h7> Fais en {project.technologie}</h7>
                </div>
            </Link>
            <div>

            </div>
        </div>
    )
}


export default Card;