import { Link } from "react-router-dom"; // import de Link pour naviguer entre chaque page
import "./footer.scss" // import du scss pour la mise en page
import images from "../../utils/images";



function Footer() {
    return (
        <div className="Footer">
            <div className="footer__links">
                <Link to="https://www.linkedin.com/in/damien-regnault-ba6b3b283/" target="_blank"> {/* _blank pour lancer le lien sur un nouvel onglet */}
                    <div
                        className="linkedin__link"
                        style={{ backgroundImage: `url(${images.linkedin})` }}
                    ></div>
                </Link>

                <Link to="https://github.com/DamienReg" target="_blank">
                    <div
                        className="github__link"
                        style={{ backgroundImage: `url(${images.github})` }}
                    ></div>
                </Link>
            </div>
            <div className="foot">
                <p>© E-SOU 2024 Tous droits réservés</p>
            </div>
        </div>
    );
};

export default Footer;
